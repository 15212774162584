<div class="container">
    <div>
        &nbsp;
    </div>
    <div>
        &nbsp;
    </div>
    <div>
        &nbsp;
    </div>
    <div class="text-center">
        <p>
            Hi <b> {{SIMRegistrationResponseModel.FirstName}} </b>, your SIM card 
            <b> {{SIMRegistrationResponseModel.SIMCardNumberICCID}} </b> 
            has been successfully registered.
            <br>
            You will be receiving a call within 24 hours, confirming all the required information needed for your SIM activation.
            <br>A notification email with your Canadian mobile number will be sent to you 48 hours prior to your arrival  date in Canada.
        </p>
        <a class="button" href="https://www.telusindia.in/">Click here to register another SIM Card</a>
    </div>

    <br>
    <br>
    <br>
    <br>
    <br>
    <br>

</div>