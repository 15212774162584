<header class="fixed-top border-bottom bg-canada text-white text-center">
  <div class="container">
    <nav class="navbar navbar-expand-md navbar-dark py-0">
      <div class="container-fluid">
        <a class="navbar-brand" href="/">
          <img src="../../assets/AppImages/TELUS_Logo.png" alt="Uniconnect" width="140">
        </a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse border-top border-top-md-0 f-italic"  id="navbarSupportedContent">
          <ul class="navbar-nav mx-auto mb-2 mb-lg-0">
            <li class="nav-item">
              <a class="nav-link fw-6 active" aria-current="page" href="#" > <img
                  src="../../assets/AppImages/Phone.png"  alt="circleimgphone" width="18px" height="30px" > <span class="textcolor">&nbsp;  9:30 AM to 6:00
                    PM Support</span> </a>
            </li>
          </ul>
          <div class="rightnav col-md-4 text-md-end">
            <div class="contactlinks d-inline-block text-md-start">
              <a href="tel:+918976012540" class="nav-item" >
                <i class="bi bi-whatsapp textcolor" ></i>
                <img src="../../assets/AppImages/callicon.jpeg" alt="Uniconnect" class="circleimg">
               <span class="textcolor">+91 89760 12540 </span>  
              </a>
              <a class="nav-item"
                href="https://api.whatsapp.com/send?phone=+918976012540&text=Lets%20talk%20to%20TelusIndia!"
                target="_blank" >
                <i class="bi bi-whatsapp textcolor"></i>
                <img src="../../assets/AppImages/whatsapp.svg" alt="Uniconnect" height="30" width="30">
<span class="textcolor"> +91 89760 12540 </span>
              </a>
              <a class="nav-item" href="mailo:info@telusindia.in">
                
                <img src="../../assets/AppImages/email1download.png" alt="circleimg" class="circleimg">
                  
                  <span class="textcolor">info@telusindia.in</span></a>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </div>
</header>