export class SIMRegistration {
  id: number;
  SIMRegistrationID: string;
  FirstName: string;
  LastName: string;
  Indianmobilenumber: string;
  SourceName: string = 'Telus';
  CityReceived: string;
  EmailID: string;
  IsConsent: boolean = true;
  IsTermandCondition: boolean;
}
